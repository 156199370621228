






















import {Component, Vue} from "vue-property-decorator";
import MyFooter from "@/components/MyFooter.vue";
import {apiEmployee, EmployeeResponse} from "@API/ApiEmployee";

// interface IEmployeeList {
//   id: number | string,
//   name: string,
//   tel: string,
//   isDefault?: boolean
// }

@Component({
  name: "Employee",
  components: {MyFooter},
})
export default class TabFour extends Vue {
  protected employeeList: EmployeeResponse[] = []
  protected choseEmployeeIndex: string | number = 0

  created() {
    this.request()
  }

  request() {
    const loading = this.$toast.loading({
      message: '加载中...',
      duration: 0,
    })

    apiEmployee.index().finally(() => {
      loading.clear()
    }).then((res) => {
      this.employeeList = res.data ? res.data : []
    }).catch(e => {
      this.$dialog({
        title: '获取员工列表失败',
        message: e.message
      })
    })
  }

  onDelete(index: number) {
    const item = this.employeeList[index]
    this.$dialog.confirm({
      title: '删除员工',
      message: `确认删除 ${item.nickname} 吗？`,
    })
        .then(() => {
          const loading = this.$toast.loading({
            message: '加载中...',
            duration: 0,
          })

          apiEmployee.untie({mobile: item.username})
              .finally(() => {
                loading.clear()
              })
              .then(() => {
                this.$toast.success({
                  message: '删除成功',
                  onClose: () => {
                    this.request()
                  }
                })
              })
              .catch(e => {
                this.$dialog({
                  title: '删除失败',
                  message: e.message
                })
              })
        })
        .catch(() => {
          // on cancel
        });
  }

  onAdd() {
    this.$router.push({
      name: 'employeeAdd'
    })
  }
}
